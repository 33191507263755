<template>
  <div
    :class="{'auth': isAuthenticated()}"
    class="example-text"
  >
    <h5 class="step-title">
      <span class="step-title__primary-text">{{ $t('services.step', [step]) }}</span>
      <span class="step-title__secondary-text"> {{ $t('services.exampleText.title') }}</span>
    </h5>
    <el-select
      v-if="examplesType == 'dropdown'"
      :value="currentExample"
      value-key="id"
      :disabled="loading"
      :popper-append-to-body="false"
      @input="onSelectInput"
    >
      <el-option
        v-for="item in items"
        :key="item.id"
        :value="item.id"
        :label="item.value"
      />
    </el-select>

    <div
      v-if="examplesType == 'tabs'"
      class="examples"
    >
      <div
        v-for="(example, index) in items"
        :key="index"
        class="examples_tab"
        :style="{ color: example.color }"
        @click="$emit('update:exampleText', example.text)"
      >
        <img
          :src="$consts.publicPath + example.icon"
          :alt="example.title"
        >
        <span> {{ example.title }}</span>
      </div>
    </div>

    <div class="example-text__textarea-box">
      <button
        v-if="isAuthenticated() && exampleText.length > 0"
        class="example-text__clear"
        @click="clearInput"
      >
        <i class="material-icons">clear</i>
      </button>
      <el-input
        :value="exampleText"
        :disabled="loading || !isAuthenticated()"
        dir="auto"
        type="textarea"
        @input="onTextareaInput"
      />
      <div
        v-show="!exampleText.length"
        class="example-text__placeholder"
      >
        <img
          :alt="$t('services.exampleText.placeholder')"
          src="@/assets/images/icons/text-icon.svg"
          class="example-text__placeholder-icon"
        >
        <div class="example-text__placeholder-text">
          {{ $t('services.exampleText.placeholder') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ExampleText',
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentExample: {
      type: Number,
      default: () => null,
    },
    exampleText: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    examplesType: {
      type: String,
      default: 'dropdown',
    },
    textAreaDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  watch: {
    currentExample(newValue) {
      if (this.items.length) {
        this.$emit('update:exampleText', this.items[newValue].value);
      }
    },
    items(newValue) {
      if (newValue.length) {
        this.$emit('update:exampleText', newValue[this.currentExample].value);
      }
    },
  },
  methods: {
    onSelectInput(newValue) {
      this.$emit('update:currentExample', newValue);
    },
    onTextareaInput(newValue) {
      this.$emit('update:exampleText', newValue);
    },
    clearInput() {
      this.$emit('update:exampleText', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.example-text {
  margin-top: 0.25rem;

  .step-title {
    @include steps-title();
  }

  @include nlp-el-select();

  &__textarea-box {
    position: relative;
    margin-top: 0.1rem;
  }

  &__clear {
    @include reset-button;
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    z-index: 1;

    i {
      color: $icon-primary;
      font-size: 0.2rem;
    }
  }

  &__placeholder {
    position: absolute;
    top: 0.33rem;
    left: calc(50% - 1.12rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2.24rem;
    text-align: center;
    pointer-events: none;
  }

  &__placeholder-text {
    font-weight: 500;
    font-size: 0.14rem;
    color: $text-gray;
  }

  .el {
    &-textarea {
      height: 1.7rem;
      padding: 0.1rem 0.08rem 0.1rem 0.1rem;
      border-radius: 0.04rem;
      background-color: $button-light-gray-bg;

      &--auth {
        padding-top: 0.35rem;
      }

      ::-webkit-scrollbar {
        width: 0.04rem;
      }

      ::-webkit-scrollbar-track {
        background: $scroll-primary-background;
      }

      ::-webkit-scrollbar-thumb {
        background: $scroll-tumb-primary-background;
        border-radius: 0.32rem;
      }
    }
  }

  &.auth .el-textarea {
    padding-top: 0.35rem;
  }

  ::v-deep {
    .el-textarea__inner {
      height: 100%;
      resize: none;
      font-size: 0.14rem;
      border: none;
      padding: 0 0.14rem 0 0;
      background: none;
      font-family: inherit;
      color: inherit;
    }
  }
}

.examples {
  margin-top: .16rem;

    &_tab {
      border: 1px solid;
      display: inline-block;
      padding: 0.07rem 0.13rem;
      border-radius: 5px;
      margin-right: 0.08rem;
      margin-bottom: 0.08rem;
      font-size: 0.1rem;
      line-height: 0.14rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
</style>
