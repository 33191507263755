<template>
  <div class="input-language">
    <step-heading
      :step="step"
      :title="$t('services.inputLanguage.title')"
    />
    <el-radio-group
      :value="currentLanguage"
      :disabled="loading"
      @input="onChange"
    >
      <el-radio-button
        v-for="item in langOptions"
        :key="item.id"
        :label="item.id"
        :disabled="disabled && checkDisabled(item.id)"
      >
        {{ item.name }}
        <span v-if="disabled && checkDisabled(item.id)"> {{ $t('services.comingSoon') }}</span>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import StepHeading from '@/views/pages/AIServices/components/StepHeading/Index.vue';

export default {
  name: 'InputLanguage',
  components: { StepHeading },
  props: {
    languages: {
      type: Array,
      required: true,
    },
    currentLanguage: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Array,
      default: null,
    },
  },
  computed: {
    langOptions() {
      return this.languages.map((lang) => ({
        id: lang,
        name: this.$t(`languages.${lang}`),
      }));
    },
  },
  methods: {
    onChange(newValue) {
      this.$emit('update:currentLanguage', newValue);
    },
    checkDisabled(id) {
      return this.disabled.includes(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-language {
  .el-radio {
    &-group {
      display: flex;
      margin-top: 0.16rem;
    }
    &-button {
      width: 100%;
    }
  }

  /deep/ .is-active .el-radio-button__inner {
    background-color: $button-primary-bg;
    color: $text-dark-bg;
  }

  /deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
  }

  /deep/ .el-radio-button__inner {
    width: 100%;
    color: #605f74;
    background: $button-light-gray-bg;
    font-weight: 600;
    font-size: 0.16rem;
    border: none;
    span {
      font-weight: 500;
      font-size: 0.16rem;
      color: rgba(96, 95, 116, 0.5);
    }
  }
}
</style>
