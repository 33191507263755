<template>
  <div id="ai-services_nlp_text-summarization">
    <service-template
      :class="{'nlp-example-rtl': currentLanguage===$consts.lang.AR}"
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :run-disabled="runDisabled"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/TSM.svg')"
      service="nlp"
    >
      <template v-slot:header>
        {{ $t('services.textSummarization.header') }}
      </template>

      <template v-slot:left>
        <input-language
          :step="1"
          :languages="availableLanguages"
          :current-language.sync="currentLanguage"
          :loading="loading"
        />
        <example-text
          :step="2"
          :items="textExamples"
          :current-example.sync="currentExample"
          :example-text.sync="exampleText"
          :loading="loading"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="3"
          :service-name="$t('services.textSummarization.header')"
          :loading="loading"
          :copyable-text="copyableText"
        >
          <div
            v-if="result && result.length"
            class="result"
          >
            <div
              v-for="(bullet, index) in result"
              :key="index"
            >
              <p>
                <span>[{{ index + 1 }}]</span>
                {{ bullet.sent }}
              </p>
              <br>
            </div>
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ExampleText from '@/views/pages/AIServices/components/ExampleText/Index.vue';
import { constants } from '@/libs/constants';

export default {
  name: 'ReadingComprehension',
  components: {
    ExampleText,
    GeneratedResult,
    InputLanguage,
    ServiceTemplate,
  },
  data: () => ({
    availableLanguages: [constants.lang.EN, constants.lang.AR],
    currentLanguage: constants.lang.EN,
    currentExample: null,
    examples: [],
    exampleText: '',
    result: null,
    loading: false,
  }),
  computed: {
    textExamples() {
      return (this.examples[this.currentLanguage] || []).map((el, index) => ({
        id: index,
        value: el,
      }));
    },

    copyableText() {
      return this.result ? this.result.map(({ sent }) => sent).join('\n\n') : '';
    },

    runDisabled() {
      return !this.exampleText || !this.currentLanguage;
    },
  },
  watch: {
    currentLanguage() {
      this.currentExample = 0;
      this.result = null;
    },
    currentExample() {
      this.result = null;
    },
    exampleText() {
      this.result = null;
    },
  },
  async created() {
    this.examples = await import('@/../public/examples/NLP/textSummarization.json');
    this.currentExample = 0;
  },

  methods: {
    async run() {
      this.loading = true;
      return this.$api.nlp.getTextSummarization({
        lang: this.currentLanguage,
        text: this.exampleText,
      });
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result.data.bullet;
    },

    runError() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  #ai-services_nlp_text-summarization {
    /deep/ .result {
      font-size: 0.16rem;
      line-height: 1.5;
      p {
        span {
          font-weight: 600;
          color: #3A71FF;
        }
      }
    }
  }
</style>
